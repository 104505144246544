import { DefaultLayout } from '../layouts/DefaultLayout';
import { Hero } from '../components/Hero';
import { TherapiesPanels } from '../components/Therapies-Panels';
import { StaticImage } from 'gatsby-plugin-image';
const breadCrumbNames = ['Services', 'Therapies'];
const breadCrumbUrls = ['/our-services/', '/therapies/'];
import { Seo } from '../components/seo';

const Therapies: Page = () => {
    return (
        <>
            <Seo
                title="Innovative Medicine Treatable Medical Illness with Therapies"
                description="We provide medical care for a number of different medical illnesses that we treat with the help of therapies."
            />
            <div className="small-container">
                <Hero
                    title="Therapies"
                    breadCrumbUrls={breadCrumbUrls}
                    breadCrumbNames={breadCrumbNames}
                >
                    {' '}
                    <StaticImage
                        className=""
                        src="../images/girl-shaking-hand.jpeg"
                        placeholder="none"
                        quality={100}
                        alt="man stressed out in office"
                    />
                </Hero>
                <p style={{ fontWeight: '700' }}>
                    There are an uncountable number of therapies beyond what our drug companies
                    offer:
                </p>{' '}
                <></>
                <TherapiesPanels />
            </div>
        </>
    );
};
Therapies.Layout = DefaultLayout;

export default Therapies;
