import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
// import { Link } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';
import { device } from './layout/GlobalStyles';
import article from '../mrna-vax.pdf';
import articleTwo from '../hormones.pdf';

const StyledSquarePanels = styled.div`
    ul {
        list-style: none;
    }
    li {
        cursor: pointer;
        text-align: center;
        margin: 16px 0px;
        background: #fcfcfc;
        display: flex;
        padding: 32px 15px;
        justify-content: center;
        align-items: center;
        border: 1.5px solid #c0cbcf;
        border-radius: 15px;
        transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
        a {
        }
    }
    li:hover {
        background: rgba(112, 201, 251, 0.2);
    }
    @media ${device.tablet} {
        ul {
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
        }
        li {
            height: 140px;
            margin-right: 15px;
            width: 250px;
        }
    }
    @media ${device.laptop} {
        li {
            width: 200px;
            a {
                position: relative;
            }
        }
    }
`;

interface Props {
    conditions: { slug: string; title: string }[];
}

export const SquarePanelsConditions: React.FC<Props> = ({ conditions }) => {
    return (
        <StyledSquarePanels>
            <ul>
                {conditions.map(condition => {
                    const { link, title, slug } = condition.node.frontmatter;
                    if (title === 'Covid VAX') {
                        return (
                            <a href={article} key="ok" download>
                                <li>
                                    <p>{title}</p>
                                </li>
                            </a>
                        );
                    } else if (title === 'Hormones are Dangerous?') {
                        return (
                            <a href={articleTwo} key="ok" download>
                                <li>
                                    <p>{title}</p>
                                </li>
                            </a>
                        );
                    } else if (link) {
                        return (
                            <a href={link} target="_blank" rel="noreferrer" key={title}>
                                <li>
                                    <p>{title}</p>
                                </li>
                            </a>
                        );
                    }

                    return (
                        <Link key={title} to={slug}>
                            <li>
                                <p>{title}</p>
                            </li>
                        </Link>
                    );
                })}
            </ul>
        </StyledSquarePanels>
    );
};
